import React from 'react';
import _ from 'lodash';
import {graphql} from 'gatsby';

import components, {Layout} from '../components/index';
import PinIcon from '../icons/PinIcon';
import MailIcon from '../icons/MailIcon';
import PhoneIcon from '../icons/PhoneIcon';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCKUGAcI1l1tDMf87fWYFUtAC4jX3rBqF8';

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

export default class Advanced extends React.Component {
    render() {
        return (
            <Layout {...this.props}>
              {(!_.get(this.props, 'pageContext.frontmatter.hide_title', null)) && (
              <header className="page-header inner-sm outer">
                <h1 className="page-title line-top">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
              </header>
              )}
              {_.map(_.get(this.props, 'pageContext.frontmatter.sections', null), (section, section_idx) => {
                  let component = _.upperFirst(_.camelCase(_.get(section, 'type', null)));
                  let Component = components[component];
                  return (
                    <Component key={section_idx} {...this.props} section={section} site={this.props.pageContext.site} />
                  )
              })}
              <div className="outer pt-3">
                <div className="block-header inner-sm">
                  <h2 className="block-title line-top">Informații de contact</h2>
                  <p className="block-subtitle" style={{ marginBottom: 20 }}>Ne găsiți la</p>
                  <iframe width="100%" height="400px" src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=Oradea,Str.+Stefan+Zweig+4`} />
                  <div style={{ display: 'flex', marginTop: 12 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                        <PhoneIcon style={{ fill: '#272c30', marginLeft: 2, marginRight: 8, width: 16 }}/>
                        <p style={{ fontWeight: 'bold', margin: 0 }}>Szűcs Ştefan - 0744579506 sau 0359452310</p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                        <PinIcon style={{ fill: '#272c30', marginRight: 6, width: 20, height: 20 }}/>
                        <p style={{ fontWeight: 'bold', margin: 0 }}>Str. Ștefan Zweig nr. 4 Oradea, Bihor, România</p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6 }}>
                        <MailIcon style={{ fill: '#272c30', marginLeft: 2, marginRight: 7, width: 16, height: 16 }}/>
                        <p style={{ fontWeight: 'bold', margin: 0 }}>alarmeautooradea@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
        );
    }
}
