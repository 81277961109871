import React from 'react';

const PinIcon = ({ style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32" style={style}>
      <g fillRule="evenodd" transform="translate(-439 -979)">
        <path fillRule="nonzero" d="M450.556 979c-6.372 0-11.556 5.199-11.556 11.59 0 7.93 10.341 19.572 10.781 20.064.414.462 1.136.461 1.549 0 .44-.492 10.781-12.134 10.781-20.065 0-6.39-5.184-11.589-11.555-11.589zm0 17.42c-3.206 0-5.814-2.616-5.814-5.83 0-3.216 2.608-5.832 5.814-5.832 3.205 0 5.813 2.616 5.813 5.831 0 3.215-2.608 5.831-5.813 5.831z"/>
      </g>
    </svg>
  );
}
 
export default PinIcon;