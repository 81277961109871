import React from 'react';

const PhoneIcon = ({ style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" style={style}>
      <g fillRule="evenodd" transform="translate(-441 -847)">
        <path fillRule="nonzero" d="M470.432 868.004c-1.959 0-3.883-.306-5.705-.909-.894-.304-1.992-.025-2.537.535l-3.598 2.716c-4.173-2.227-6.743-4.796-8.94-8.938l2.636-3.504c.685-.684.93-1.683.636-2.62-.605-1.833-.912-3.756-.912-5.716 0-1.416-1.152-2.568-2.568-2.568h-5.876c-1.416 0-2.568 1.152-2.568 2.568C441 865.797 454.203 879 470.432 879c1.416 0 2.568-1.152 2.568-2.568v-5.86c0-1.416-1.152-2.568-2.568-2.568z"/>
      </g>
    </svg>
  );
}
 
export default PhoneIcon;